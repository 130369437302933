
html,
body 
  padding: 0
  margin: 0
  height: 100%
  background-color: whitesmoke
  font-family: Rubik
  font-size: 10px

.mat-tooltip
  font-size: 2rem!important